(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name postponements.editPostponement.controller:EditPostponementCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.postponements.edit')
    .controller('EditPostponementCtrl', EditPostponementCtrl);

  function EditPostponementCtrl($scope, $mdToast, $state, $filter, LastPostponement, facilities, entities, match, recoveryDates) {
    var vm = this;
    vm.ctrlName = 'EditPostponementCtrl';

    vm.suggestedDates = parseRecoveryDates(angular.copy(recoveryDates));

    vm.facilities = facilities;
    vm.localTeam = match.localTeam;
    vm.localTeam.columnName = 'CHAMPIONSHIPS.LOCAL_TEAM';

    vm.visitorTeam = match.visitorTeam;
    vm.visitorTeam.columnName = 'CHAMPIONSHIPS.VISITOR_TEAM';

    vm.postponement = _.cloneDeep(match.lastPostponement);
    vm.postponement.time = moment(vm.postponement.time, 'HH:mm').toDate();

    vm.canSetTime = checkCanSetTime();
    vm.canSetFacility = checkCanSetFacility();
    vm.updatePostponement = updatePostponement;
    vm.cancelEdition = cancelEdition;

    function cancelEdition() {
      vm.postponement = _.cloneDeep(match.lastPostponement);
      vm.postponement.time = moment(vm.postponement.time, 'HH:mm').toDate();
    }

    function checkCanSetTime() {
      var item = findItemById(entities, vm.localTeam.entityId);
      return angular.isDefined(item);
    }

    function checkCanSetFacility() {
      return checkCanSetTime();
    }

    function updatePostponement() {
      var postObject = {};
      postObject.date = moment(vm.postponement.date).format('YYYY-MM-DD');
      postObject.time = angular.isUndefined(vm.postponement.time) || !checkCanSetTime() ? null : moment(vm.postponement.time).format('HH:mm');
      postObject.facilityId = !checkCanSetFacility() ? null : vm.postponement.facilityId;
      postObject.details = vm.postponement.details;

      LastPostponement.update({id: match.id}, postObject, function () {
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')('POSTPONEMENTS.SUCCESS_EDITION'))
            .position('bottom left')
            .hideDelay(3000)
        );
        $state.go('home.postponements.postponedMatches');
      }, function (error) {
        var message;
        if (error.status === 400) {
          message = 'POSTPONEMENTS.' + error.data.message;
        } else {
          message = 'SERVER_ERROR';
        }
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')(message))
            .position('bottom left')
            .hideDelay(3000)
        );
      });
    }

    function findItemById(collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    }

    function parseRecoveryDates(recoveryDates) {
      var dates = [];
      angular.forEach(recoveryDates, function (date) {
        dates.push(moment(date).format('L'));
      });
      return dates;
    }

    $scope.$watch('vm.selectedRecoveryDate',
      function setToCalendar(stringDate) {
        if (angular.isDefined(stringDate)) {
          vm.postponement.date = moment(stringDate, "DD/MM/YYYY").toDate();
        } else {
          vm.postponement.date = match.lastPostponement.date;
        }
      }
    );
  }
}());
